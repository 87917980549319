import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const ServiceCard = (props) => {
  return(
    <Card>
      <Card.Img variant="top" src={props.ImgSrc} />
      <Card.Body>
        <Card.Title>{props.Title}</Card.Title>
        <Card.Text>{props.Text}</Card.Text>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button href={props.href}>Details</Button>
      </Card.Footer>
    </Card>
  )
};

export default ServiceCard;
