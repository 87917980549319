import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import { graphql } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image";
import Button from "react-bootstrap/Button";
import CardDeck from "react-bootstrap/CardDeck"
import ServiceCard from "../components/serviceCard"
import imgHandWash from "../images/washing-car-1397382_640.jpg";
import imgDeoderize from "../images/deoderize.jpg";
import imgStainGuard from "../images/stainguard.jpg";
import Alert from "react-bootstrap/Alert";

export default ({data}) => {

  const heroImage = data.headerImage.childImageSharp.fluid;
  return(
    <Layout>
      <Hero
        Title={"Our Services"}
        BgColor={"black"}
        Subtitle={"Rugged Cross Services"}
        Img={heroImage}
      />
      <Container>
        <Row>
          <Col>
            <Alert variant="success">
              We accept: cash, check, Visa, MasterCard, Discover, American Express.
            </Alert>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.fullDetailImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2 id={"full"}>Full Detail</h2>
            <p>This service includes all the bells and whistles starting with a thorough hand-washing and drying of your vehicle. The exterior receives a single step paint correction and is finished off with wax (up to 4 months of protection). If you would like long-lasting paint sealer instead...</p>
            <Button href={"/services/full-detail"}>Full Detail Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.exteriorDetailImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>Exterior Detail</h2>
            <p>Made for the vehicle that needs a little extra care on its exterior. This service involves a thorough hand wash, cleaning of the wheels, and tire dressing. The exterior receives a single step paint correction and is finished off with wax (up to 4 months of protection). If you would like long-lasting paint sealer instead...</p>
            <Button href={"/services/exterior-detail"}>Exterior Detail Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.raptorImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>Raptor Protective Coating</h2>
            <p>
              RAPTOR lining services are offered for truck bed liners, as an overall vehicle surface protectant, and for other surfaces. Multiple colors and color-matching are available.</p>
            <Button href={"/services/raptor-protective-coating"}>RAPTOR Protective Coating Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.oneYearImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>1 Year Paint Sealant</h2>
            <p>
              The 1 year paint sealer is formulated to outlast and out-perform other paint sealants. The sealant contains a bonding agent that maximizes the longevity and durability. In just one step, the paint sealer forms a tough coating with true staying power. It also provides resistance from UV rays...</p>
            <Button href={"/services/1-year-paint-sealer"}>1 Year Paint Sealer Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.twoYearImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>2 year Ceramic Coating</h2>
            <p>2 year ceramic coating is a nano-glass ceramic coating that provides paint protection measured in years, not months. Formulated using nano-glass ceramic particles, ceramic coating creates a hard-as-nails protective barrier and a glass-like, liquid shine.</p>
            <Button href={"/services/2-year-ceramic-coating"}>2 Year Ceramic Coating Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.threeYearImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>3 Year Ceramic Coating</h2>
            <p>3 year ceramic paint coating blankets your vehicle in a glossy shell of ceramic protection that complements all colors. Black will appear deep; red will dazzle with bright reflections; silvers and white will look like molten glass.</p>
            <Button href={"/services/3-year-ceramic-coating"}>3 Year Ceramic Coating Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col>
            <h2>Ceramic Recharge Service</h2>
            <p>This service is offered to customers who have had either the 2 year or 3 year ceramic coating put on their vehicle. This service recharges the ceramic and extends the life of the coating.</p>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.interiorDetailImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>Interior Detail</h2>
            <p>A great service for your car during the rainy months to remove all the built up mud and grime. It includes a thorough vacuuming with deep upholstery and carpet extraction, which removes most stains and all the dirt.</p>
            <Button href={"/services/interior-detail"}>Interior Detail Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.handWashImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>Hand Wash</h2>
            <p>This service involves a thorough hand wash, cleaning of the wheels, and tire dressing. Painted surfaces will be also waxed (up to 4 months protection).</p>
            <Button href={"/services/hand-wash"}>Hand Wash Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.engineCleaningImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>Engine Cleaning</h2>
            <p>This includes degreasing of your vehicle’s engine. Great for cleaning up after an oil leak.</p>
            <Button href={"/services/engine-cleaning"}>Engine Cleaning Service</Button>
          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col sm="12" md="6">
            <Img fluid={data.carpetShampooImage.childImageSharp.fluid}/>
          </Col>
          <Col sm="12" md="6">
            <h2>Carpet and Upholstery Shampoo</h2>
            <p>This service includes a thorough vacuuming with deep upholstery and carpet extraction, which removes most stains and all the dirt.</p>
            <Button href={"/services/carpet-shampoo"}>Carpet and Upholstery Shampoo Service</Button>
          </Col>
        </Row>
        <h1 className={'text-center'}>Additional Services</h1>
        <Row className={"extra-space"}>
          <CardDeck>
            <ServiceCard ImgSrc={imgHandWash} href="/services/additional-services#new" Title={"New Car Service"} Text="This service involves a thorough hand washing, drying and vacuuming of your vehicle. A Clay Bar will be used to remove contaminants found on your vehicle. Then a high-quality paint sealant will be applied. The paint sealant will provide resistance from UV rays, salt air, snow, sleet, insects, road film, harsh detergents and environmental contaminants that can fade or discolor automotive finishes prematurely."/>
            <ServiceCard ImgSrc={imgDeoderize} href="/services/additional-services#interior" Title={"Interior Deodorizing"} Text="If a bad smell is lingering in your vehicle, clear the air with an ozone treatment. Ozone generators use nature’s powerful deodorizer to help remove odors and clean the air in your vehicle."/>
            <ServiceCard ImgSrc={imgStainGuard} href="/services/additional-services#stainguard" Title={"Stainguard"} Text="Stain-resistant fabric protectant is applied to cloth and carpet. This service can be combined with other services."/>
          </CardDeck>
        </Row>
      </Container>
    </Layout>
  )
}

export const serviceImages = graphql`
    fragment serviceImage on File {
      childImageSharp {
        fluid(quality: 90, maxWidth: 590) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `;

export const data = graphql`
  query {
    fullDetailImage: file(relativePath: { eq: "full_detail.jpg" }) {
      ...serviceImage
    }
    exteriorDetailImage: file(relativePath: { eq: "exterior_detail.jpg" }) {
      ...serviceImage
    }
    oneYearImage: file(relativePath: { eq: "PaintSealer.jpg" }) {
      ...serviceImage
    }
    twoYearImage: file(relativePath: { eq: "2-year_ceramic-service.JPG" }) {
      ...serviceImage
    }
    threeYearImage: file(relativePath: { eq: "3-year-ceramic-service.JPG" }) {
      ...serviceImage
    }
    interiorDetailImage: file(relativePath: { eq: "interior_detail.jpg" }) {
      ...serviceImage
    }
    handWashImage: file(relativePath: { eq: "hand_wash.jpg" }) {
      ...serviceImage
    }
    engineCleaningImage: file(relativePath: { eq: "EngineCleaningSlide_0.png" }) {
      ...serviceImage
    }
    carpetShampooImage: file(relativePath: { eq: "carpet_upholstery.JPG" }) {
      ...serviceImage
    }
    raptorImage: file(relativePath: { eq: "raptor.jpg" }) {
      ...serviceImage
    }
    headerImage: file(relativePath: { eq: "cleaning-1837331_1920.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
